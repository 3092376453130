import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { SectionTitle } from "../components/SectionTitle"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StickyImage } from "../components/stickyImage"

const Legalnotice = () => {
    const pageData = useStaticQuery(graphql`
        query {
            contentfulLegalNotice {
                title
            }
            contentfulLegalNoticeBodyRichTextNode {
                json
            }
        }
    `)

    return (
        <Layout>
            <StickyImage />
            <MainComponent>
                <SectionTitle title={pageData.contentfulLegalNotice.title} />
                <Body>
                    <p>{documentToReactComponents(pageData.contentfulLegalNoticeBodyRichTextNode.json)}</p>
                </Body>
            </MainComponent>
        </Layout>
    )
}

export default Legalnotice

const MainComponent = styled.div`
    margin: 2rem 1.5rem;
`

const Body = styled.div`
    width: 90%;
    font-size: 0.7rem;
    display: flex;

    & > p {
        padding-right: 2rem;
    }
`
